import { computed, inject } from '@angular/core';
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { PortalUser } from '@pinnakl/portal-v2/domain';
import { withDevtools } from '@pinnakl/shared/signal-store';
import { PortalUserApiService } from './portal-user-api.service';

interface PortalUserStoreState {
  portalUserLoading: boolean;
  portalUserLoaded: boolean;
  portalUserProcessing: boolean;
  portalUser: PortalUser | null;
  selectedClientPublicId: string | null;
}

export const PortalUserStore = signalStore(
  { providedIn: 'root' },
  withDevtools('portalData'),
  withState<PortalUserStoreState>({
    portalUserLoading: false,
    portalUserLoaded: false,
    portalUser: null,
    portalUserProcessing: false,
    selectedClientPublicId: null
  }),
  withComputed(store => ({
    selectedClient: computed(() => {
      const portalUser = store.portalUser();
      const selectedClientPublicId = store.selectedClientPublicId();
      return portalUser?.clients && selectedClientPublicId
        ? (portalUser.clients.find(client => client.publicId === selectedClientPublicId) ?? null)
        : null;
    })
  })),
  withMethods((store, apiService = inject(PortalUserApiService)) => ({
    setSelectedClientPublicId: (selectedClientPublicId: string): void => {
      patchState(store, { selectedClientPublicId });
    },
    loadPortalUser: async (): Promise<PortalUser> => {
      patchState(store, { portalUserLoading: true });
      const user = await apiService.loadPortalUser();
      patchState(store, {
        portalUserLoaded: true,
        portalUserLoading: false,
        portalUser: user
      });
      if (user.clients.length) {
        patchState(store, { selectedClientPublicId: user.clients[0].publicId });
      }

      return user;
    },
    updatePortalUser: async (user: Partial<PortalUser>): Promise<PortalUser> => {
      patchState(store, { portalUserProcessing: true });
      const updatedUser = await apiService.updatePortalUser(user);

      patchState(store, {
        portalUserProcessing: false,
        portalUser: {
          ...updatedUser,
          clients: store.portalUser()?.clients ?? updatedUser.clients ?? []
        }
      });
      return updatedUser;
    }
  }))
);
