import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface AvatarConfig {
  color: string;
  backgroundColor: string;
  letter: string;
}

type ColorMap = Record<string, string[]>;

@Component({
  standalone: true,
  selector: 'pinnakl-first-letter-avatar',
  templateUrl: './first-letter-avatar.component.html',
  styleUrls: ['./first-letter-avatar.component.scss'],
  imports: [CommonModule]
})
export class FirstLetterAvatarComponent implements OnChanges {
  private _config: BehaviorSubject<AvatarConfig | null> = new BehaviorSubject<AvatarConfig | null>(
    null
  );
  private colors: ColorMap = {
    '#e57373': ['a', 'b'],
    '#f06292': ['c', 'd'],
    '#ba68c8': ['e', 'f'],
    '#9575cd': ['g', 'h'],
    '#7986cb': ['i', 'j'],
    '#64b5f6': ['k', 'l'],
    '#4fc3f7': ['m', 'n'],
    '#4dd0e1': ['o', 'p'],
    '#4db6ac': ['q', 'r'],
    '#81c784': ['s', 't'],
    '#aed581': ['u', 'v'],
    '#ff8a65': ['w', 'x'],
    '#d4e157': ['y', 'z']
  };
  private backgroundColors: Record<string, string[]> = {
    'rgba(229, 115, 115, 0.4)': ['a', 'n'],
    'rgba(240, 98, 146, 0.4)': ['b', 'o'],
    'rgba(186, 104, 200, 0.4)': ['c', 'p'],
    'rgba(149, 117, 205, 0.4)': ['d', 'q'],
    'rgba(121, 134, 203, 0.4)': ['e', 'r'],
    'rgba(100, 181, 246, 0.4)': ['f', 's'],
    'rgba(79, 195, 247, 0.4)': ['g', 't'],
    'rgba(77, 208, 225, 0.4)': ['h', 'u'],
    'rgba(77, 182, 172, 0.4)': ['i', 'v'],
    'rgba(129, 199, 132, 0.4)': ['j', 'w'],
    'rgba(174, 213, 129, 0.4)': ['k', 'x'],
    'rgba(255, 138, 101, 0.4)': ['l', 'y'],
    'rgba(212, 225, 87, 0.4)': ['m', 'z']
  };
  @Input({ required: true }) nameString!: string;
  @Input() width = '24px';
  @Input() height = '24px';
  @Input() containerClasses = 'border-circle';
  @Input() color?: string;
  @Input() backgroundColor?: string;
  @Input() useRandomBackgroundColor = false;
  @Input() textClasses: string[] = ['text-xs', 'font-medium'];
  @Input() opaque = false;
  @HostBinding('class') class = this.opaque ? 'relative' : '';
  config$ = this._config.asObservable();

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['nameString'].currentValue &&
      changes['nameString'].currentValue !== changes['nameString'].previousValue
    ) {
      this.initAvatar();
    }
  }

  private initAvatar(): void {
    const firstLetter = this.nameString.trim().charAt(0).toUpperCase();
    this._config.next({
      color: this.color ?? this.getColor(firstLetter, this.colors),
      backgroundColor:
        this.backgroundColor ?? this.getColor(firstLetter, this.backgroundColors, true),
      letter: firstLetter
    });
  }

  private getColor(letter: string, colorMap: ColorMap, isBgColor = false): string {
    if (this.useRandomBackgroundColor && isBgColor) {
      const colors = Object.keys(colorMap);
      return colors[Math.floor(Math.random() * colors.length)];
    }
    const keys = Object.keys(colorMap);
    const color = Object.keys(colorMap).find(key => colorMap[key].includes(letter.toLowerCase()));
    return color ?? keys[0];
  }
}
