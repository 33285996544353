import { inject, Injectable } from '@angular/core';
import { UserService } from '@pinnakl/core/data-providers';
import { WebServiceProvider } from '@pinnakl/core/web-services';
import { PortalUser } from '@pinnakl/portal-v2/domain';
import { V3_ENDPOINT } from '@pinnakl/shared/constants';
import { getErrorMessage } from '@pinnakl/shared/util-helpers';
import { PinnaklUIToastMessage } from '@pinnakl/shared/util-providers';
import { firstValueFrom, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortalUserApiService {
  private readonly portalUserEndpoint = `${V3_ENDPOINT}crm_vdr_user`;
  private readonly userService = inject(UserService);
  private readonly wsp = inject(WebServiceProvider);
  private readonly toast = inject(PinnaklUIToastMessage);

  async loadPortalUser(): Promise<PortalUser> {
    try {
      return await firstValueFrom(this.getUser());
    } catch (error) {
      this.toast.warning('Unable to fetch portal user information. ' + getErrorMessage('', error));
      throw error;
    }
  }

  async updatePortalUser(user: Partial<PortalUser>): Promise<PortalUser> {
    try {
      return await firstValueFrom(this.updateUser(user));
    } catch (error) {
      this.toast.warning('Unable to update portal user information. ' + getErrorMessage('', error));
      throw error;
    }
  }

  private getUser(): Observable<PortalUser> {
    return this.wsp.get<PortalUser>({
      endpoint: `${this.portalUserEndpoint}/${this.userService.getUser()?.id}`
    });
  }

  private updateUser(user: Partial<PortalUser>): Observable<PortalUser> {
    return this.wsp.put({ endpoint: this.portalUserEndpoint, body: user });
  }
}
