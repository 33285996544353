@if (config$ | async; as config) {
  <div
    [style.background-color]="config.backgroundColor"
    [style.color]="config.color"
    [style.width]="width"
    [style.height]="height"
    [class.relative]="opaque"
    class="z-2 flex flex-column align-items-center justify-content-center {{ containerClasses }}"
  >
    <span [ngClass]="textClasses">{{ config.letter }}</span>
  </div>
  @if (opaque) {
    <div
      [style.background-color]="'white'"
      [style.width]="width"
      [style.height]="height"
      class="z-1 absolute top-0 left-0 right-0 bottom-0"
    ></div>
  }
}
